import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public languages: string[] = ['en', 'tr'];
    public selectedLanguage: BehaviorSubject<LanguageService | string> = new BehaviorSubject<LanguageService | string>(null);

    constructor(public translate: TranslateService) {
        let browserLang;
        translate.addLangs(this.languages);

        if (localStorage.getItem('lang')) {
            browserLang = this.detectLanguage(localStorage.getItem('lang'));
            this.selectedLanguage.next(browserLang);
        } else {
            browserLang = this.detectLanguage(translate.getBrowserLang());
            this.selectedLanguage.next(browserLang);
        }
        translate.use(browserLang.match(/en|tr/) ? browserLang : 'tr');
    }

    public setLanguage(lang: any): void {
        this.translate.use(lang);
        localStorage.setItem('lang', lang);
        this.selectedLanguage.next(lang);
    }

    public getLanguage(): string {
        if (localStorage.getItem('lang')) {
            return this.detectLanguage(localStorage.getItem('lang'));
        } else {
            return this.detectLanguage(navigator.language);
        }
    }

    detectLanguage(language: string): string {
        if (language.search('en')) {
            return 'en';
        } else if (language.search('tr')) {
            return 'tr';
        }
    }
}
