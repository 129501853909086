import { Inject, Injectable } from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { LanguageService } from './language.service'

interface ClassNameI {
  color: string
  logoColor: string
  backgroundColor: string
  favicon: string
}

@Injectable({
  providedIn: 'root'
})
export class RouterTrackerService {
  public className: BehaviorSubject<ClassNameI | null> = new BehaviorSubject<ClassNameI | null>(null)
  private pageLanguage: string

  constructor(public router: Router, private languageService: LanguageService) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.className.next(this.colorFinder(event.url))
      }
    })
    this.pageLanguage = languageService.getLanguage()
  }

  colorFinder(url: string): ClassNameI {
    // console.log(url);
    if (url === '/welcome') {
      return {
        color: 'color-green',
        logoColor: 'green-logo',
        backgroundColor: 'bg-color-green',
        favicon: 'green-logo-en'
      }
    } else if (url === '/livestream') {
      return {
        color: 'color-green',
        logoColor: 'green-logo',
        backgroundColor: 'bg-color-green',
        favicon: 'green-logo-en'
      }
    } else if (url === '/sponsors') {
      return {
        color: 'color-blue',
        logoColor: 'blue-logo',
        backgroundColor: 'bg-color-blue',
        favicon: 'blue-logo-en'
      }
    } else if (url === '/programme') {
      return {
        color: 'color-brown',
        logoColor: 'brown-logo',
        backgroundColor: 'bg-color-brown',
        favicon: 'brown-logo-en'
      }
    } else if (url === '/speakers') {
      return {
        color: 'color-orange',
        logoColor: 'orange-logo',
        backgroundColor: 'bg-color-orange',
        favicon: 'orange-logo-en'
      }
    }
    return {
      color: 'color-green',
      logoColor: 'green-logo',
      backgroundColor: 'bg-color-green',
      favicon: 'green-logo-en'
    }
  }
}
