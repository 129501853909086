import {HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {CryptoService} from '../../../services/crypto.service';
import {endPointProd, endPointTest} from '../../../../environments/environment.prod';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit(): void {

  }

}
